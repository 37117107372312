import { useFetch } from "nuxt/app";
import { varFetch } from "~/variables/varFetch.js";

export const $useFetch = (request, opts) => {
	const { baseURL, session } = varFetch();

	return useFetch(request, {
		baseURL,
		headers: {
			Authorization: `Bearer ${session.value?.user?.accessToken}`,
		},
		...opts,
	});
};
