/* global useAuth */
import { useRuntimeConfig } from "nuxt/app";

export const varFetch = () => {
	const config = useRuntimeConfig();
	const { data: session } = useAuth();

	let baseURL = config.public.REST_API_BROWSER_URL;
	if (typeof process !== "undefined" && process?.server) baseURL = config.public.REST_API_SERVER_URL;

	return {
		session,
		baseURL,
	};
};
